// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const conf =require('../../config.json');

//conso
const empresa="scs";



export const environment = {
  production: false,
  mapBox: 'pk.eyJ1Ijoic2VyamVzMTAiLCJhIjoiY2s4djUycGVmMGZ0bTNlcWhxd24yY3hpdCJ9.4eBV1R16_Db3GMGIBFNQaw',
  apiUrlConfig:conf[empresa]['apiUrlConfig'],
  apiUrlVentas:conf[empresa]['apiUrlVentas'],
  apiUrlCompras:conf[empresa]['apiUrlCompras'],
  apiUrlUtils:conf[empresa]['apiUrlUtils'],
  apiUrlInventario:conf[empresa]['apiUrlInventario'],
  apiUrlRRHH:conf[empresa]['apiUrlRRHH'],
  apiUrlSecurity: conf[empresa]['apiUrlSecurity'],
  apiUrlAfiliados:conf[empresa]['apiUrlAfiliados'],
  apiUrlNotificaciones:conf[empresa]['apiUrlNotificaciones'], 
  apiUrlContabilidad:conf[empresa]['apiUrlContabilidad'], 
  apiUrlDashboard:conf[empresa]['apiUrlDashboard'], 
  apiUrlCuentasPorCobrar:conf[empresa]['apiUrlCuentasPorCobrar'],
  apiUrlHospital:conf[empresa]['apiUrlHospital'],  
  apiUrlCreditos:conf[empresa]['apiUrlCreditos'],  
  apiUrlAgenda:conf[empresa]['apiUrlAgenda'],  
  apiUrlTest:conf[empresa]['apiUrlTest'],  

  
  ventas:"ventas",
  transporte:"ventasTransporte",
  site: "go504",
  processEnv: 'dev',
  tokenLocal: "token_go504",
  idEmpresa:1,
  dataUsuarioLocal:"data_go504",
  idAfiliado: "-1",
  nombreEmpresa: "CCDH",
  porcentajeValidezFotos: 80,
  rutaDefecto: 'login',
  empresa: empresa,


};

// https://5u6oi0h2ya.execute-api.us-east-1.amazonaws.com/dev


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
